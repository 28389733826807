@font-face {
    font-family: inter;
    font-weight: 700;
    src: url(assets/font/inter/woff2/Inter-Bold.woff2) format('woff2'),
    url(assets/font/inter/ttf/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: inter;
    font-weight: 600;
    src: url(assets/font/inter/woff2/Inter-SemiBold.woff2) format('woff2'),
    url(assets/font/inter/ttf/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: inter;
    font-weight: 500;
    src: url(assets/font/inter/woff2/Inter-Medium.woff2) format('woff2'),
    url(assets/font/inter/ttf/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: inter;
    font-weight: 400;
    src: url(assets/font/inter/woff2/Inter-Regular.woff2) format('woff2'),
    url(assets/font/inter/ttf/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: inter;
    font-weight: 300;
    src: url(assets/font/inter/woff2/Inter-Light-BETA.woff2) format('woff2'),
    url(assets/font/inter/ttf/Inter-Light-BETA.ttf) format('truetype');
}

body {
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, inter, sans-serif;
    line-height: 22px;
    font-size: 14px;
    letter-spacing: -0.007em;
    color: #4a4a4a;
}

*, *:before, *:after {
    box-sizing: border-box;
}

